import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from "vue-meta";
import { RegisterChartComponent } from "./ChartRegistration";

import DefaultLayout from "./layouts/DefaultLayout";
import SimpleLayout from "./layouts/SimpleLayout";
import { Fragment } from "vue-fragment";
import i18n from "./i18n";

import "@/styles/global.scss";
import "@/styles/typography.scss";
import "@/styles/wx_variables.scss";

import * as authRedirect from "./authentication/authRedirect";
import RouteService from "./router/RouteService";

RegisterChartComponent();
Vue.component("default-layout", DefaultLayout);
Vue.component("simple-layout", SimpleLayout);
Vue.component("fragment", Fragment);

Vue.config.productionTip = false;

// Route guard
router.beforeEach((to, from, next) => {
  const user = authRedirect.getUser();
  const isLoggedIn = store.getters["user/isLoggedIn"];
  const isTilelyticsEnabled = store.getters["user/isTilelyticsEnabled"];

  if (user && !isLoggedIn) {
    authRedirect.processAuthenticatedUser(user);
  }
  if (user && isLoggedIn && !isTilelyticsEnabled) {
    if (to.name === "UserNotAuthorized") {
      next();
      return;
    } else {
      next(RouteService.toUserNotEnabled());
      return;
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!user) {
      next(RouteService.toHome());
      return;
    }
  } else if (user) {
    next(RouteService.toCompanyOverview());
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAdmin) && !store.state.user.isAdmin) {
    next(RouteService.toCompanyOverview());
    return;
  }
  next();
});

Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
