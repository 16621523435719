import i18n from "../../i18n";
import AvailabilitiesHelper from "./helpers/AvailabilitiesHelper";
import AvailabilityService from "../../components/availabilityAndDowntime/AvailabilityService";
import FormatFunctions from "../../components/general/FormatFunctions";

export default {
  namespaced: true,

  state: {
    availabilityDateRange: null,

    startDate: null,
    endDate: null,
    assignSF1Data: false,

    dtbyprod: [],
    dtbyprodGraph: [],
    dtstatsbyinterval: [],
    downtimeIntervalType: "P",
    dttopcausesPlanned: [],
    dttopcausesUnplanned: [],
    fetchingDowntimeDataGraph: false,
    fetchingDowntimes: false,
    fetchingDtCauses: false,
    intervalTypes: AvailabilitiesHelper.getDefaultIntervalTypes(),
    isValidInterval: true,
    maxNumberOfDays: 1,
    dateRangeIsTooLarge: true,
  },

  getters: {
    availabilityDateRange(state) {
      return state.availabilityDateRange;
    },
    period(state) {
      if (state.startDate !== null && state.endDate !== null) {
        return i18n.t("period.interpolated.fromDateToDate", [
          state.startDate.substr(0, 10),
          state.endDate.substr(0, 10),
        ]);
      } else {
        return i18n.t("period.noPeriodSelected");
      }
    },
    assignSF1Data(state) {
      return state.assignSF1Data;
    },
    dtbyprod(state) {
      return state.dtbyprod;
    },
    dtbyprodGraph(state) {
      return state.dtbyprodGraph;
    },
    dtstatsbyinterval(state) {
      return state.dtstatsbyinterval;
    },
    downtimeIntervalType(state) {
      return state.downtimeIntervalType;
    },
    dttopcausesPlanned(state) {
      return state.dttopcausesPlanned;
    },
    dttopcausesUnplanned(state) {
      return state.dttopcausesUnplanned;
    },
    fetchingDowntimeDataGraph(state) {
      return state.fetchingDowntimeDataGraph;
    },
    fetchingDowntimes(state) {
      return state.fetchingDowntimes;
    },
    fetchingDtCauses(state) {
      return state.fetchingDtCauses;
    },
    intervalTypes(state) {
      return state.intervalTypes;
    },
    isValidInterval(state) {
      return state.isValidInterval;
    },
    maxNumberOfDays(state) {
      return state.maxNumberOfDays;
    },
    dateRangeIsTooLarge(state) {
      return state.dateRangeIsTooLarge;
    },
  },

  actions: {
    updateAssignSF1Data({ commit }, data) {
      commit("updateAssignSF1Data", data);
    },
    updateDowntimeDataGraphIntervalType({ commit }, data) {
      commit("updateDowntimeDataGraphIntervalType", data);
    },
    updateFetchingDowntimeDataGraph({ commit }, data) {
      commit("updateFetchingDowntimeDataGraph", data);
    },
    updateFetchingDowntimes({ commit }, data) {
      commit("updateFetchingDowntimes", data);
    },
    updateFetchingDtCauses({ commit }, data) {
      commit("updateFetchingDtCauses", data);
    },
    fetchDowntimes({ state, commit }, payload) {
      if (state.fetchingDowntimes) return; // Already fetching data!
      commit("updateFetchingDowntimes", true);
      AvailabilityService.getDowntimesByProductionUnit(payload)
        .then((response) => {
          const downtimesByPU = response.data.downtimes_per_production_unit.map((d) => ({
            production_line_name: d.production_unit_name,
            availability_ratio: d.availability_rate / 100,
            downtime_frequency: d.downtime_occurrences,
            total_downtime_duration_seconds: d.total_downtime_duration / 1000,
            planned_downtime_duration_seconds: d.planned_downtime_duration / 1000,
            unplanned_downtime_duration_seconds: d.unplanned_downtime_duration / 1000,
            unjustified_downtime_duration_seconds: d.unjustified_downtime_duration / 1000,
            production_duration_seconds: d.in_production_duration / 1000,
            uptime_duration_seconds: d.uptime_duration / 1000,
          }));
          commit("updateDtByProd", downtimesByPU);
          commit("updateFetchingDowntimes", false);
        })
        .catch((error) => {
          commit("updateFetchingDowntimes", false);
          console.log(error);
        });
    },
    fetchDowntimeStatsByInterval({ state, commit, rootGetters }, payload) {
      if (state.fetchingDowntimeDataGraph) return; // Already fetching the data
      const factoryTimezone = rootGetters["navigation/activeFactory"].timezone;
      commit("updateFetchingDowntimeDataGraph", true);
      AvailabilityService.getDowntimesStatsIntervals(payload)
        .then((response) => {
          const downtimeStatsByInterval = response.data.downtimes_interval_per_pu.map((interval) => ({
            production_line_id: interval.production_unit_id,
            production_line_name: interval.production_unit_name,
            downtimes: interval.downtimes_per_interval.map((d) => ({
              start_time: FormatFunctions.isoDateToFormat(d.start_time, factoryTimezone, "yyyy-MM-dd"), // "2021-11-27",
              end_time: FormatFunctions.isoDateToFormat(d.end_time, factoryTimezone, "yyyy-MM-dd"),
              shift: "",
              availability_ratio: d.availability_rate / 100.0,
              planned_downtime_duration_seconds: d.planned_downtime_duration / 1000,
              unplanned_downtime_duration_seconds: d.unplanned_downtime_duration / 1000,
              unjustified_downtime_duration_seconds: d.unjustified_downtime_duration / 1000,
              total_downtime_duration_seconds: d.total_downtime_duration / 1000,
              total_downtime_employee_cost: 0.0,
              downtime_frequency: d.downtime_occurrences,
            })),
          }));
          commit("updateDtStatsByInterval", downtimeStatsByInterval);
          commit("updateFetchingDowntimeDataGraph", false);
        })
        .catch((error) => {
          commit("updateFetchingDowntimeDataGraph", false);
          console.log(error);
        });
    },
    fetchCauses({ state, commit }, payload) {
      if (state.fetchingDtCauses) return; // already fetching the data
      commit("updateFetchingDtCauses", true);
      AvailabilityService.getDowntimeCauses(payload)
        .then((response) => {
          const planned = response.data.downtime_reasons
            .filter((cause) => cause.is_planned === true)
            .map((cause) => ({
              downtime_cause: cause.downtime_reason,
              total_downtime_duration_seconds: cause.total_downtime_duration_seconds / 1000, // Despite the name of the field cause the json response, the value is ni millis!
              is_planned: true,
            }));
          const unplanned = response.data.downtime_reasons
            .filter((cause) => cause.is_planned === false)
            .map((cause) => ({
              downtime_cause: cause.downtime_reason,
              total_downtime_duration_seconds: cause.total_downtime_duration_seconds / 1000, // Despite the name of the field cause the json response, the value is ni millis!
              is_planned: false,
            }));
          commit("updateDtCausesPlanned", planned);
          commit("updateDtCausesUnplanned", unplanned);
          commit("updateFetchingDtCauses", false);
        })
        .catch((error) => {
          commit("updateFetchingDtCauses", false);
          console.log(error);
        });
    },
    fetchDowntimesDataGraph({ commit }, payload) {
      commit("updateFetchingDowntimeDataGraph", true);
      AvailabilityService.getDowntimesByProductionUnit(payload)
        .then((response) => {
          const downtimesByPU = response.data.downtimes_per_production_unit.map((d) => ({
            production_line_name: d.production_unit_name,
            availability_ratio: d.availability_rate / 100,
            downtime_frequency: d.downtime_occurrences,
            total_downtime_duration_seconds: d.total_downtime_duration / 1000,
            planned_downtime_duration_seconds: d.planned_downtime_duration / 1000,
            unplanned_downtime_duration_seconds: d.unplanned_downtime_duration / 1000,
            production_duration_seconds: d.in_production_duration / 1000,
            uptime_duration_seconds: d.uptime_duration / 1000,
          }));
          commit("updateDtByProdGraph", downtimesByPU);
          commit("updateFetchingDowntimeDataGraph", false);
        })
        .catch(function (error) {
          commit("updateFetchingDowntimeDataGraph", false);
          console.log(error);
        });
    },
    setDateRange({ commit }, data) {
      commit("setDateRange", data);
    },
  },

  mutations: {
    setDateRange(state, data) {
      state.availabilityDateRange = data;
    },
    updateAssignSF1Data(state, data) {
      state.assignSF1Data = data;
    },
    updateDtByProd(state, data) {
      state.dtbyprod = data;
    },
    updateDtByProdGraph(state, data) {
      state.dtbyprodGraph = data;
    },
    updateDtStatsByInterval(state, data) {
      state.dtstatsbyinterval = data;
    },
    updateDowntimeDataGraphIntervalType(state, data) {
      state.downtimeIntervalType = data;
    },
    updateDtCausesPlanned(state, data) {
      state.dttopcausesPlanned = data;
    },
    updateDtCausesUnplanned(state, data) {
      state.dttopcausesUnplanned = data;
    },
    updateFetchingDowntimeDataGraph(state, data) {
      state.fetchingDowntimeDataGraph = data;
    },
    updateFetchingDowntimes(state, data) {
      state.fetchingDowntimes = data;
    },
    updateFetchingDtCauses(state, data) {
      state.fetchingDtCauses = data;
    },
  },
};
