import Vue from "vue";
import Vuex from "vuex";
import accounts from "./modules/accounts";
import availability from "./modules/availability";
import dashboard from "./modules/dashboard";
import user from "./modules/user";
import myreports from "./modules/myreports";
import operation from "./modules/operation";
import companyoverview from "./modules/companyoverview";
import navigation from "./modules/navigation";
import filters from "./modules/filters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    companyoverview,
    navigation,
    accounts,
    availability,
    dashboard,
    myreports,
    operation,
    user,
    filters,
  },
});
