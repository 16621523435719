import http from "@/BaseTilelyticsApiHttp";

class FiltersService {
  getAccountFilters() {
    return http.get("filters");
  }

  getAccountFilterGroups() {
    return http.get("filter-groups");
  }

  createFilter(payload) {
    return http.post("filters", payload);
  }
}

export default new FiltersService();
