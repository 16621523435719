import http from "../../BaseTilelyticsApiHttp";

class AvailabilityService {
  getDowntimesByProductionUnit(requestPayload) {
    // aka `/dtbyprod`
    return http.post(`/production-units/downtimes-info`, requestPayload);
  }
  getDowntimesStatsIntervals(requestPayload) {
    // aka `/downtime_stats/intervals`
    return http.post(`/production-units/downtimes-info-interval`, requestPayload);
  }
  getDowntimeCauses(requestPayload) {
    // aka `/dttopcauses`
    return http.post(`/top-downtime-reasons`, requestPayload);
  }
}

export default new AvailabilityService();
