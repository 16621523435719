//The order of imports is very important. date-fns *must* be before luxon
import "chartjs-adapter-date-fns";
import "chartjs-adapter-luxon";
import {
    Chart,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    TimeScale,
  } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";


export function RegisterChartComponent() {
    Chart.register(Title, Tooltip, Legend, BarElement, LineElement, LinearScale, CategoryScale, PointElement, TimeScale, annotationPlugin);
}
