import DashboardService from "../../components/dashboard/DashboardService";

export default {
  namespaced: true,

  state: {
    kpisByProductionUnit: null,
    timeAggregation: null,
    justificationTypeCounters: {
      planned_downtime_count: 0,
      unplanned_downtime_count: 0,
      unjustified_downtime_count: 0,
      mixed_downtime_count: 0,
      partially_justified_downtime_count: 0,
    },
    topUnplannedCauses: [],
    downtimeByCategory: [],
    fetchingKPIsByProductionUnit: false,
    fetchingDowntimeSummary: false,
    dashboardDateRange: null,
    appliedStartDateDashboard: undefined,
    appliedEndDateDashboard: undefined,
  },

  getters: {
    kpisByProductionUnit(state) {
      return state.kpisByProductionUnit;
    },
    fetchingKPIsByProductionUnit(state) {
      return state.fetchingKPIsByProductionUnit;
    },
    timeAggregation(state) {
      return state.timeAggregation;
    },
    justificationTypeCounters(state) {
      return state.justificationTypeCounters;
    },
    topUnplannedCauses(state) {
      return state.topUnplannedCauses;
    },
    downtimeByCategory(state) {
      return state.downtimeByCategory;
    },
    fetchingDowntimeSummary(state) {
      return state.fetchingDowntimeSummary;
    },
    dashboardDateRange(state) {
      return state.dashboardDateRange;
    },
    appliedStartDateDashboard(state) {
      return state.appliedStartDateDashboard;
    },
    appliedEndDateDashboard(state) {
      return state.appliedEndDateDashboard;
    },
  },

  actions: {
    fetchGroupKPIs({ commit }, payloadAndService) {
      // if (state.fetchingKPIsByProductionUnit) return; // Already fetching KPIs
      commit("setFetchingProductionUnitKpisInfo", true);
      payloadAndService.service
        .getKPIsByGroups(payloadAndService.payload)
        .then((httpResponse) => {
          commit("setTimeAggregation", payloadAndService.payload.time_aggregation);
          commit("setKpisByProductionUnit", httpResponse.data);
          commit("setFetchingProductionUnitKpisInfo", false);
        })
        .catch(() => {
          commit("setFetchingProductionUnitKpisInfo", false);
        });
    },
    fetchDowntimesSummary({ state, commit }, requestData) {
      if (state.fetchingDowntimeSummary) return; // Already fetching KPIs
      commit("setFetchingDowntimesSummary", true);
      DashboardService.getProductionUnitSummary(requestData)
        .then((response) => {
          commit("setFetchingDowntimesSummary", false);
          if (response.status === 200 && response.data) {
            commit("setJustificationTypeCounters", response.data.justification_type_count_summary);
            commit("setTopUnplannedDowntimeReasons", response.data.top_unplanned_downtimes);
            commit("setTopDowntimeCategories", response.data.top_downtime_categories);
          }
        })
        .catch(() => {
          commit("setFetchingDowntimesSummary", false);
        });
    },
    updateAppliedStartDateDashboard({ commit }, data) {
      commit("updateAppliedStartDateDashboard", data);
    },
    updateAppliedEndDateDashboard({ commit }, data) {
      commit("updateAppliedEndDateDashboard", data);
    },
    updateTimeAggregation({ commit }, data) {
      commit("setTimeAggregation", data);
    },
    setDashboardDateRange({ commit }, data) {
      commit("setDashboardDateRange", data);
    },
  },

  mutations: {
    setFetchingProductionUnitKpisInfo(state, data) {
      state.fetchingKPIsByProductionUnit = data;
    },
    setKpisByProductionUnit(state, data) {
      state.kpisByProductionUnit = data;
    },
    setTimeAggregation(state, data) {
      state.timeAggregation = data;
    },
    setJustificationTypeCounters(state, data) {
      state.justificationTypeCounters = data;
    },
    setTopUnplannedDowntimeReasons(state, data) {
      state.topUnplannedCauses = data;
    },
    setTopDowntimeCategories(state, data) {
      state.downtimeByCategory = data;
    },
    setFetchingDowntimesSummary(state, data) {
      state.fetchingDowntimeSummary = data;
    },
    setDashboardDateRange(state, data) {
      state.dashboardDateRange = data;
    },
    updateAppliedStartDateDashboard(state, data) {
      state.appliedStartDateDashboard = data;
    },
    updateAppliedEndDateDashboard(state, data) {
      state.appliedEndDateDashboard = data;
    },
  },
};
