<template>
  <v-app id="wxapp" :class="{ presenter: isPresenter }" :language="this.language">
    <component :is="layout" v-bind="layoutProps" />
    <v-snackbar
      v-model="snackBar.show"
      timeout="5000"
      :color="snackBar.status"
      class="wxapp-top-alert"
      absolute
      top
      center
      vertical
      min-width="auto"
    >
      {{ snackBar.message }}
      <template #action="{ attrs }">
        <v-btn text outlined v-bind="attrs" @click="hideOperation">
          {{ $t("common.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

const default_layout = "default-layout";

export default {
  metaInfo() {
    return {
      title: this.$t("meta.title"),
      htmlAttrs: {
        lang: this.language,
      },
      meta: [{ name: "description", content: this.$t("meta.description") }],
    };
  },
  components: {},
  data() {
    return {
      refreshAccountSettingsInterval: null,
      isPendoInitializing: false,
      isPendoInitialized: false,
    };
  },
  computed: {
    ...mapGetters("accounts", ["account"]),
    ...mapGetters("user", [
      "isPresenter",
      "language",
      "theme",
      "isLoggedIn",
      "email",
      "username",
      "loggedInUserRole",
      "accountId",
      "userId",
    ]),
    ...mapGetters("operation", ["snackBar"]),
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    layoutProps() {
      return this.$route.meta.layoutProps;
    },
    activeTheme() {
      return this.theme;
    },
  },

  watch: {
    activeTheme() {
      this.$vuetify.theme.dark = this.theme === "dark";
    },
    isLoggedIn(newValue, oldValue) {
      // Since
      if (newValue && newValue !== oldValue) {
        this.initializeSecuredFetches();
        this.initializePendoSession();
      }
    },
    account() {
      this.initializePendoSession();
    },
  },
  methods: {
    ...mapActions("accounts", ["fetchAccountSettings"]),
    ...mapActions("operation", ["hideOperation"]),
    ...mapActions("user", ["fetchAvailableCurrencies"]),
    initializeSecuredFetches() {
      clearInterval(this.refreshAccountSettingsInterval);
      this.refreshAccountSettingsInterval = setInterval(() => this.fetchAccountSettings(this.accountId), 3600000); // refresh every hour
      this.fetchAvailableCurrencies();
    },
    initializePendoSession() {
      if (this.isPendoInitialized || this.isPendoInitializing) return;

      if (!this.isLoggedIn || !this.account) return;

      this.isPendoInitializing = true;
      try {
        // need to suppress since pendo is installed via script in index.html therefore eslint can't find it
        // eslint-disable-next-line no-undef
        pendo.initialize({
          visitor: {
            id: this.userId,
            email: this.email,
            full_name: this.username,
            role: this.loggedInUserRole,
          },
          account: {
            id: this.account.id,
            name: this.account.name,
            insights_enabled: this.account.insights_enabled,
            is_tilelytics: this.account.is_tilelytics,
            domain_name: this.account.domain_name,
          },
        });
        this.isPendoInitializing = false;
        this.isPendoInitialized = true;
      } catch (error) {
        this.isPendoInitializing = false;
        this.isPendoInitialized = false;
      }
    },
  },
  mounted() {
    this.initializePendoSession();
  },
};
</script>

<style lang="scss" scoped>
.v-application {
  &#wxapp {
    // gradient is set within layouts
    background-color: var(--color-base-theme);
  }

  // isMobile
  .wxapp-top-alert {
    position: fixed;
    top: 55px;
    z-index: 999;
  }

  // !isMobile
  @media ($wx-md-min) {
    .wxapp-top-alert {
      top: 0;
    }
  }

  /** - - - - -
   * Presenter
   */
  &.presenter {
    padding: var(--grid-gutter);
    background-color: var(--color-pu-current-state);
    position: relative;
    transition: background-color 0.3s linear;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: var(--grid-gutter) solid var(--color-pu-current-state);
      z-index: 6;
      pointer-events: none;
      transition: border-color 0.3s linear;
    }

    ::v-deep .v-application--wrap {
      min-height: calc(100vh - var(--grid-gutter) * 2);
    }

    ::v-deep .v-navigation-drawer {
      max-height: calc(100% - var(--grid-gutter) * 2) !important;
      left: var(--grid-gutter) !important;
      top: var(--grid-gutter) !important;
    }
  }
}

.v-list {
  .alert-message {
    color: var(--color-flat-panel-theme);
  }
}
</style>
