<template>
  <fragment>
    <v-app-bar class="wxapp-main-header" :app="isMobile" :absolute="!isMobile" :color="appBarColor">
      <v-row class="flex-row-reverse mt-0 mb-0">
        <!-- 
          Right column
          The only of the 3 cols that stays visible for all breakpoints
          -->
        <v-col cols="6" md="12" class="d-flex align-center justify-end pt-0 pb-0">
          <apps-menu-window />
          <main-settings />
        </v-col>
        <!-- 
          Left column
          -->
        <v-col v-if="isMobile" cols="6" class="d-flex align-center pt-0 pb-0">
          <v-btn
            @click="$emit('toggleDrawerState')"
            :title="$t('mainHeader.menu')"
            class="nav-menu-btn ml-n1"
            icon
            large
          >
            <v-icon>{{ drawerMenuIcon }}</v-icon>
          </v-btn>
          <v-btn
            :to="getDestinationRoute"
            :title="$t('mainNav.logoAltText')"
            color="primary"
            class="wxapp-logo--mobile"
            exact
            plain
          />
        </v-col>
      </v-row>
    </v-app-bar>
  </fragment>
</template>

<script>
import AppsMenuWindow from "@/components/AppsMenuWindow.vue";
import MainSettings from "@/components/MainSettings.vue";
import RouteService from "@/router/RouteService";
import { mapGetters } from "vuex";

export default {
  name: "MainHeader",
  components: { MainSettings, AppsMenuWindow },
  props: {
    drawerState: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters("user", ["isSupervisor", "email"]),
    getDestinationRoute() {
      return RouteService.toCompanyOverview();
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isHomeUrl() {
      return this.$route.path.includes("home");
    },
    appBarColor() {
      return this.isMobile ? "var(--color-base-background)" : "transparent";
    },
    drawerMenuIcon() {
      return this.drawerState ? "mdi-close" : "mdi-menu";
    },
  },
};
</script>

<style lang="scss" scoped>
.wxapp-main-header {
  &.v-toolbar.v-app-bar {
    z-index: 8;
    box-shadow: var(--box-shadow-small-elevation);

    @media ($wx-md-min) {
      z-index: 1; // required for display hover wxapp-main-page
      left: auto !important; // flex aligned to right
      max-width: 320px; // constrain the width so wxapp-main-page content stays clickable
      border-bottom-left-radius: var(--border-radius-lg); // preventing design for z-index
      box-shadow: none;
    }
    @media ($wx-lg-min) {
      max-width: 375px; // allow factory-selector full width on large viewport
    }
    @media ($wx-xl-min) {
      margin-top: 10px !important; // vertically align with `wxapp-logo--drawer`
    }

    ::v-deep .v-toolbar__content {
      // constrain the height to ($vuetify.application.top value of) 56px
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// gutter between right buttons
::v-deep .v-btn {
  margin-right: calc(var(--grid-gutter) / 2);

  &:last-child {
    margin-right: 0;
  }
}

.wxapp-logo--mobile.v-btn {
  // local vars
  $logo_iconSize: 34px;
  $logo_padding: 8px;
  // style
  height: ($logo_iconSize + $logo_padding);
  width: ($logo_iconSize + $logo_padding);
  min-width: $logo_iconSize;
  margin: 0 3em 0 0;
  padding: 0;
  border-radius: $logo_padding;
  background: url("~@/assets/Tilelytics-Logo-Square-Light.svg") center center no-repeat;
  background-size: $logo_iconSize;

  @media ($wx-xs-max) {
    margin-right: 2em; // allign to center
  }

  // initially the default image (above) was `Tilelytics-Logo-Square-Dark.svg`
  /* &.theme--light {
    background-image: url("~@/assets/Tilelytics-Logo-Square-Light.svg");
  } */
}
</style>
