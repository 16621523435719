import http from "../../BaseTilelyticsApiHttp";

import i18n from "../../i18n";

class MyReportsService {
  getMyReports(factoryId) {
    return http.get(`/factories/${factoryId}/user/email-reports`);
  }

  getReport(reportId) {
    return http.get(`/user/email-reports/${reportId}`);
  }

  deleteReport(reportId) {
    return http.delete(`/user/email-reports/${reportId}`);
  }

  createReport(factory_id, newReport) {
    return http.post(`/factories/${factory_id}/user/email-reports`, newReport);
  }

  updateReport(reportId, report) {
    return http.put(`/user/email-reports/${reportId}`, report);
  }

  toTemplateTitle(template) {
    switch (template) {
      case "downtime_main_page":
        return i18n.t("Templates.downtime_main_page");
      case "downtime_by_production_line":
        return i18n.t("Templates.downtime_by_production_line");
      case "downtime_by_shift":
        return i18n.t("Templates.downtime_by_shift");
      case "overview":
        return i18n.t("Templates.overview");
      default:
        return i18n.t("Templates.downtime_main_page");
    }
  }
}

export default new MyReportsService();
