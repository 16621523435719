import { DateTime, Duration } from "luxon";

export default {
  timeFormat(seconds, format) {
    if (format === undefined || format === null || format === "") {
      return Duration.fromMillis(1000 * seconds).toFormat("hh'h' mm'm'");
    } else {
      return Duration.fromMillis(1000 * seconds).toFormat(format);
    }
  },
  isoDateToFormat(isoDate, targetedTimezone, format) {
    return DateTime.fromISO(isoDate)
      .setZone(targetedTimezone)
      .toFormat(format);
  },
  retrieveSeconds: function(timeString) {
    const timeArray = timeString.split(" ").map((el) => parseInt(el.slice(0, -1)));
    return timeArray[0] * 86400 + timeArray[1] * 3600 + timeArray[2] * 60;
  },
  financial: (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  leadingZero(value) {
    return ("0" + value).toString().slice(-2);
  },
  durationText(durationMillis) {
    if (durationMillis === null || durationMillis === undefined) {
      return Duration.fromMillis(0).toFormat("hh'h' mm'm'");
    }
    if (durationMillis < 60000) {
      return Duration.fromMillis(durationMillis).toFormat("ss's'");
    }
    return Duration.fromMillis(durationMillis).toFormat("hh'h' mm'm'");
  }
};
