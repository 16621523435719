import MyReportsService from "../../components/myReport/MyReportsService";
import ErrorHandling from "../../components/ErrorHandling";
import i18n from "../../i18n";

export default {
  namespaced: true,

  state: {
    reportsList: [],
  },
  getters: {
    reportsList(state) {
      return state.reportsList;
    },
  },
  actions: {
    fetchMyReports({ commit }, factoryId) {
      return MyReportsService.getMyReports(factoryId)
        .then((response) => {
          if (response.status === 200) {
            commit("setReports", response.data);
          }
        })
        .catch((error) =>
          commit(
            "operation/showOperationError",
            ErrorHandling.buildErrorsMessages(error.response, (code) =>
              i18n.t("common.errors.default", { code: code }),
            ),
            { root: true },
          ),
        );
    },
  },
  mutations: {
    setReports(state, reports) {
      state.reportsList = reports;
    },
  },
};
