import * as msal from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import store from "../store";
import router from "../router";
import UserService from "@/components/user/UserService";
import i18n, { setLanguageToLocalStorage } from "@/i18n";
import RouteService from "@/router/RouteService";
import ErrorHandling from "@/components/ErrorHandling";
import { setThemeToLocalStorage } from "@/components/Theme";

export const msalClient = new msal.PublicClientApplication(msalConfig);

msalClient
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.log(error);
    // Cancels forgot password
    if (error.errorMessage.indexOf("AADB2C90091") > -1) {
      signOut();
    }
    if (error.errorMessage.indexOf("AADB2C90027") > -1) {
      store.commit("operation/showOperationError", [i18n.t("homepage.unknownUser")]);
      store.commit("user/clearUser");
      localStorage.clear();
      sessionStorage.clear();
      window.setTimeout(() => {
        msalClient.loginRedirect(loginRequest()).catch((err) => {
          console.log(err);
          store.commit("user/clearUser");
        });
      }, 5000);
    }
  });

msalClient.addEventCallback((message) => {
  if (message.eventType === msal.EventType.LOGOUT_SUCCESS) {
    store.commit("user/clearUser");
  }
});

async function handleResponse(authenticationResult) {
  if (authenticationResult) {
    await processAuthenticatedUser(authenticationResult);
    router.push(RouteService.toRoot()).catch(() => {});
  }
}

export async function processAuthenticatedUser(user) {
  const accountId = user.idTokenClaims.account_id;

  setUser(user);
  setAccountId(accountId);
  return Promise.all([
    loadUserProfile(user),
    loadAccountSettings(accountId),
    loadCurrencies(),
    loadCustomPeriods(),
  ]);
}

export function setUser(user) {
  store.commit("user/setUser", {
    userId: user.idTokenClaims.user_id,
    role: user.idTokenClaims.role,
    email: user.idTokenClaims.email,
    username: user.idTokenClaims.display_name,
    isAdmin: user.idTokenClaims.is_admin,
    isFinancialAdmin: user.idTokenClaims.is_financial_admin,
    isTilelyticsEnabled: user.idTokenClaims.is_tilelytics,
    isWxUser: user.idTokenClaims.is_wx_user,
    insightsEnabled: user.idTokenClaims.insights_enabled,
  });
  localStorage.setItem("currentUserId", user.idTokenClaims.user_id);
}

function setAccountId(accountId) {
  store.commit("user/setAccountId", accountId);
}

export async function loadUserProfile(user) {
  const userId = user.idTokenClaims.user_id;
  const userRole = user.idTokenClaims.role;
  await store.dispatch("user/fetchUserFactory", userId, userRole);
  let preferences;
  await UserService.getUserPreferences()
    .then((response) => {
      if (response.status === 200) {
        preferences = response.data;
      }
    })
    .catch((error) => {
      store.commit(
        "operation/showOperationError",
        ErrorHandling.buildErrorsMessages(error.response, () => i18n.t("common.errors.loadPreferences")),
        { root: true },
      );
    });
  let theme = store.getters["user/theme"];
  if (preferences) {
    theme = preferences.theme;
    store.commit("user/setPreferences", preferences);
    store.commit("navigation/updateTimeRange", {
      value: preferences.tilelytics.selected_time_option,
      start_date: preferences.tilelytics.selected_start_date,
      end_date: preferences.tilelytics.selected_end_date,
    });
  }
  i18n.locale = store.getters["user/language"];
  setLanguageToLocalStorage(i18n.locale);
  setThemeToLocalStorage(theme === "dark");
}

async function loadAccountSettings(accountId) {
  return store.dispatch("accounts/fetchAccountSettings", accountId);
}

function loadCustomPeriods() {
  return store.dispatch("navigation/fetchCustomPeriods");
}

function loadCurrencies() {
  return store.dispatch("user/fetchAvailableCurrencies");
}

export function signIn() {
  try {
    msalClient.loginRedirect(loginRequest());
  } catch (err) {
    console.log(err);
    store.commit("user/clearUser");
  }
}

export function signOut() {
  const logoutRequest = {
    account: getUser(),
  };
  msalClient
    .logoutRedirect(logoutRequest)
    .then(() => store.commit("user/clearUser"))
    .catch((err) => {
      console.log(err);
    });
  localStorage.removeItem("currentUserId");
}

export async function getTokenRedirect(request) {
  request.account = getUser();
  return msalClient
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new msal.InteractionRequiredAuthError();
      }
      return response.accessToken;
    })
    .catch((error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalClient.acquireTokenRedirect(request);
      } else {
        if (error instanceof msal.BrowserAuthError) {
          let errorCode = error.errorCode;
          if (errorCode === "no_account_error" || errorCode === "post_request_failed") {
            // fallback to interaction when silent call fails
            return msalClient.acquireTokenRedirect(request);
          } else {
            // If the error is something like "no_network_connectivity", return null and wait for the next call to `getTokenRedirect`
            return null;
          }
        } else {
          console.log(error);
        }
      }
    });
}

export function getUser() {
  if (!msalClient) {
    return null;
  }
  const currentAccounts = msalClient.getAllAccounts();
  if (currentAccounts.length === 0) {
    // No user signed in
    return null;
  } else {
    if (currentAccounts.length === 1) {
      return addUsername(currentAccounts[0]);
    } else {
      let currentUserId = localStorage.getItem("currentUserId");
      if (!currentUserId) {
        return null;
      }
      let currentAccount = currentAccounts.find((a) => a.idTokenClaims.user_id === currentUserId);
      if (!currentAccount) {
        return null;
      } else {
        return addUsername(currentAccount);
      }
    }
  }
}

function addUsername(account) {
  account.username = account.idTokenClaims.email;
  return account;
}
