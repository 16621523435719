import FormatFunctions from "../../../components/general/FormatFunctions";
import { DateTime } from "luxon";

export const DATE_FORMAT = "yyyy-LL-dd";

export const ONE_HOUR_IN_MILLIS = 60 /* minutes */ * 60 /* seconds */ * 1000; /* millis */

export default {
  HOUR_MINUTE: "LL-dd HH:mm",
  YEAR_MONTH: "yyyy-LL",
  DATE_FORMAT: "yyyy-LL-dd",
  DATE_TIME_FORMAT: "yyyy-LL-dd HH:mm",

  toEpochMillis(yearMonthDay, timezone) {
    const stringToDate = DateTime.fromFormat(yearMonthDay, DATE_FORMAT);
    const year = stringToDate.year;
    const month = stringToDate.month;
    const dayOfMonth = stringToDate.day;
    const dateUTC = DateTime.now()
      .setZone(timezone)
      .set({ year: year, month: month, day: dayOfMonth, hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toUTC();
    return dateUTC.toISO();
  },
  toDate(yearMonthDay, timezone) {
    const stringToDate = DateTime.fromFormat(yearMonthDay, DATE_FORMAT);
    const year = stringToDate.year;
    const month = stringToDate.month;
    const dayOfMonth = stringToDate.day;
    const dateUTC = DateTime.now().setZone(timezone).set({ year: year, month: month, day: dayOfMonth }).toUTC();
    return dateUTC.toISODate();
  },
  // Deprecated
  getTimeSpanFromDateDifference: (startDate, endDate) => {
    let millisecs = new Date(endDate) - new Date(startDate);
    const days = Math.floor(millisecs / 1000 / 60 / 60 / 24);
    millisecs -= days * 1000 * 60 * 60 * 24;
    const hours = Math.floor(millisecs / 1000 / 60 / 60);
    millisecs -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(millisecs / 1000 / 60);
    millisecs -= minutes * 1000 * 60;
    const seconds = Math.floor(millisecs / 1000);
    millisecs -= seconds * 1000;
    return {
      Days: days,
      Hours: hours,
      Minutes: minutes,
      Seconds: seconds,
      Milliseconds: millisecs,
    };
  },

  getStartAndEndDates(periodSelection, weekStartDayId, timezone) {
    let nowInTimezone = DateTime.now().setZone(timezone);
    switch (periodSelection.toLowerCase()) {
      case "year": {
        let startDate = nowInTimezone.set({ month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        let endDate = startDate.plus({ year: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "month": {
        // `current month`
        let startDate = nowInTimezone.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
        let endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "lastmonth": {
        let startDate = nowInTimezone
          .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
          .minus({ month: 1 });
        let endDate = startDate.plus({ month: 1 }).minus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "week": {
        const today = new Date();
        const startOfWeek = this.getStartOfWeek(today, weekStartDayId);
        return {
          startDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek)),
            false,
          ),
          endDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek + 6)),
            false,
          ),
        };
      }
      case "lastweek": {
        const today = new Date();
        const startOfWeek = this.getStartOfWeek(today, weekStartDayId);
        return {
          startDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek - 7)),
            false,
          ),
          endDate: this.getTimestamp(
            new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(startOfWeek - 1)),
            false,
          ),
        };
      }
      case "last7days": {
        let endDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let startDate = endDate.minus({ day: 7 }).plus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "last30days": {
        let endDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let startDate = endDate.minus({ day: 30 }).plus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "last90days": {
        let endDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let startDate = endDate.minus({ day: 90 }).plus({ day: 1 });
        return {
          startDate: startDate.toFormat(DATE_FORMAT),
          endDate: endDate.toFormat(DATE_FORMAT),
        };
      }
      case "day": {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let formattedDate = startDate.toFormat(DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
      case "lastday": {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).minus({ day: 1 });
        let formattedDate = startDate.toFormat(DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
      default: {
        let startDate = nowInTimezone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        let formattedDate = startDate.toFormat(DATE_FORMAT);
        return {
          startDate: formattedDate,
          endDate: formattedDate,
        };
      }
    }
  },
  // Deprecated
  getStartOfWeek(today, weekStartDayId) {
    const beforeSettingsWeekStartDay = new Date().getDay() < weekStartDayId % 7;
    return today.getDate() - today.getDay() + (weekStartDayId % 7) + (beforeSettingsWeekStartDay ? -7 : 0);
  },
  // Deprecated
  getTimestamp(date, addTime) {
    if (typeof date === "string" || date instanceof String) {
      return addTime ? date + " 00:00:00" : date;
    } else {
      let fullYear = date.getFullYear();
      let month = FormatFunctions.leadingZero(date.getMonth() + 1);
      let day = FormatFunctions.leadingZero(date.getDate());
      const formattedDate = `${fullYear}-${month}-${day}`;
      return addTime ? formattedDate + " 00:00:00" : formattedDate;
    }
  },
};
