export default {
  namespaced: true,

  state: {
    selectedFactories: null,
    selectedPeriod: "lastday",
    selectedPeriodStartEnd: null,
    selectedMetric: null,
    weekStartDay: "monday",
    weekStartDayId: 1,

    factoriesMetricsStatsReady: false,
    factoriesMetricsStats: null,
    factoriesMetricsStatsWeightedAverages: null,
  },

  getters: {
    selectedMetric(state) {
      return state.selectedMetric;
    },
    selectedFactories(state) {
      return state.selectedFactories;
    },
    selectedFactory(state) {
      return state.selectedFactory;
    },
  },

  actions: {
    setSelectedMetric({ commit }, metric) {
      commit("setSelectedMetric", metric);
    },
    setSelectedFactories({ commit }, factories) {
      commit("setSelectedFactories", factories);
    },
  },

  mutations: {
    setSelectedMetric(state, metric) {
      state.selectedMetric = metric;
    },
    setSelectedFactories(state, factories) {
      state.selectedFactories = factories;
    },
  },
};
