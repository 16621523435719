import timeUtility from "./TimeUtils";
import i18n from "../../../i18n";

const getDefaultIntervalTypes = () => {
  return {
    Hour: {
      key: "Hour",
      backendIdentifier: "H",
      maxNumberOfDays: 14,
      minimumHours: 1,
      minimumDays: 1,
    },
    Day: {
      key: "Day",
      backendIdentifier: "D",
      maxNumberOfDays: 30,
      minimumDays: 1,
    },
    Week: {
      key: "Week",
      backendIdentifier: "W",
      maxNumberOfDays: 60,
      minimumDays: 7,
    },
    Month: {
      key: "Month",
      backendIdentifier: "Month",
      maxNumberOfDays: 365,
      minimumDays: 31,
    },
  };
};

const getNewConstraints = (startDate, endDate, intervalType) => {
  const timespan = timeUtility.getTimeSpanFromDateDifference(startDate, endDate);
  let isValidInterval;
  let dateRangeIsTooLarge;
  const intervalTypes = getNewIntervalTypes(timespan);
  const defaultIntervalTypes = getDefaultIntervalTypes();
  const constraint = defaultIntervalTypes[intervalType];
  if (intervalType === "Hour") {
    isValidInterval = timespan.Hours >= constraint.minimumHours || timespan.Days >= constraint.minimumDays;
  } else {
    isValidInterval = timespan.Days >= constraint.minimumDays;
  }

  const maxNumberOfDays = constraint.maxNumberOfDays;
  dateRangeIsTooLarge = timespan.Days > maxNumberOfDays;
  return { intervalTypes, isValidInterval, maxNumberOfDays, dateRangeIsTooLarge };
};

const getDefaultIntervalType = (startDate, endDate) => {
  const timespan = timeUtility.getTimeSpanFromDateDifference(startDate, endDate);
  const defaultIntervalTypes = getDefaultIntervalTypes();
  // instead of getting the exact smallest interval type, get it only
  // if the timespan is half of its limit because the absolute limit
  // results in approximately 15 seconds of fetch time. this results in
  // an 'average' interval type. this strategy is open for discussion
  // it is merely a simple strategy that we use for the moment to select
  // a default time interval upon fetching data with the 'Apply' button
  if (timespan.Days < 14 / 2) {
    return defaultIntervalTypes.Hour;
  } else if (timespan.Days < 60 / 2) {
    return defaultIntervalTypes.Day;
  } else if (timespan.Days < 180 / 2) {
    return defaultIntervalTypes.Week;
  } else if (timespan.Days < 365) {
    return defaultIntervalTypes.Month;
  } else {
    return i18n.t("AvailabilityHeatmap.NotAllowed");
  }
};

const getNewIntervalTypes = (timespan) => {
  const defaultIntervalTypes = getDefaultIntervalTypes();
  const intervalTypes = [];
  intervalTypes.push(defaultIntervalTypes.Hour);
  if (timespan.Hours >= 1 || timespan.Days >= 1) {
    intervalTypes.push(defaultIntervalTypes.Day);
  }
  if (timespan.Days >= 7) {
    intervalTypes.push(defaultIntervalTypes.Week);
  }
  if (timespan.Days >= 31) {
    intervalTypes.push(defaultIntervalTypes.Month);
  }
  return intervalTypes;
};

const canFetch = (isValidInterval, dateRangeIsTooLarge, startDate, endDate, productionLIneIDs) => {
  return isValidInterval && !dateRangeIsTooLarge && productionLIneIDs !== null && startDate != null && endDate != null;
};

export default {
  getNewConstraints,
  getDefaultIntervalType,
  canFetch,
  getDefaultIntervalTypes,
};
