import http from "../../../BaseTilelyticsApiHttp";
import { DateTime, Interval } from "luxon";

class CustomPeriodService {
  getCustomPeriods() {
    return http.get(`/custom-periods`);
  }
  getCustomPeriod(periodId) {
    return http.get(`/custom-periods/${periodId}`);
  }
  createPeriod(newPeriod) {
    return http.post(`/custom-periods`, newPeriod);
  }
  updatePeriod(period) {
    return http.put(`/custom-periods/${period.id}`, period);
  }
  deletePeriod(periodId) {
    return http.delete(`/custom-periods/${periodId}`);
  }
  getPeriodNameMaxLength() {
    return 6;
  }
  getPeriodMaxCoverageInDays() {
    return 185;
  }
  isPeriodNameValid(name) {
    return /^(?:(?![×Þß÷þø])[-_ 0-9a-zÀ-ÿ])+$/.test(String(name).toLowerCase());
  }
  calculateCoverageInDays(startDate, endDate) {
    const start = DateTime.fromFormat(startDate, "yyyy-LL-dd");
    const end = DateTime.fromFormat(endDate, "yyyy-LL-dd");
    const diff = Interval.fromDateTimes(start, end);
    return diff.length("days");
  }
}

export default new CustomPeriodService();
