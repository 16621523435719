import http from "../../BaseConfigurationApiHttp";

class FactoryService {
  getFactoriesForUser() {
    return http.get(`/user/factories`);
  }
  updateFactorySettings(factoryId, newSettings) {
    return http.put(`factories/${factoryId}`, newSettings);
  }
  getFactoryWorkShifts(factoryId) {
    return http.get(`factories/${factoryId}/assigned-work-shift-names`);
  }
  getFactoryProducts(factoryId) {
    return http.get(`factories/${factoryId}/products`);
  }
  getAvailableCurrencyCodes() {
    return http.get(`/currency-list`);
  }
}

export default new FactoryService();
