import FiltersService from "../../components/filters/FiltersService";

export default {
  namespaced: true,
  state: {
    filters: [],
    filterGroups: [],
    currentFilter: undefined,
    filterSummaryShowState: false,
  },
  getters: {
    filters(state) {
      return state.filters;
    },
    filterGroups(state) {
      return state.filterGroups;
    },
    currentFilter(state) {
      return state.currentFilter;
    },
    filterSummaryShowState(state) {
      return state.filterSummaryShowState;
    },
  },

  actions: {
    fetchFilters({ commit }) {
      return FiltersService.getAccountFilters()
        .then((response) => {
          commit("setFilters", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchFilterGroups({ commit }) {
      return FiltersService.getAccountFilterGroups()
        .then((response) => {
          commit("setFilterGroups", response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleFilterSummary({ state, commit }) {
      if (state.filterSummaryShowState) {
        commit("filterSummaryShowState", false);
      } else {
        commit("filterSummaryShowState", true);
      }
    },
    hideFilterSummary({ commit }) {
      commit("filterSummaryShowState", false);
    },
  },

  mutations: {
    setFilters(state, filters) {
      state.filters = filters;
    },
    setFilterGroups(state, filterGroups) {
      state.filterGroups = filterGroups;
    },
    setCurrentFilter(state, currentFilter) {
      state.currentFilter = currentFilter;
    },
    filterSummaryShowState(state, show) {
      state.filterSummaryShowState = show;
    },
  },
};
