export default {
  namespaced: true,

  state: {
    snackBar: {
      status: "",
      message: "",
      show: false,
    },
  },

  getters: {
    snackBar(state) {
      return state.snackBar;
    },
  },

  actions: {
    showOperationSuccess({ commit }, message) {
      commit("showOperationSuccess", message);
    },
    showOperationError({ commit }, message) {
      commit("showOperationError", message);
    },
    hideOperation({ commit }) {
      commit("hideOperation");
    },
  },

  mutations: {
    showOperationSuccess(state, message) {
      Object.assign(state.snackBar, {
        status: "success",
        message: message,
        show: true,
      });
    },
    showOperationError(state, message) {
      if (message && message.length > 0) {
        Object.assign(state.snackBar, {
          status: "error",
          message: message,
          show: true,
        });
      }
    },
    hideOperation(state) {
      Object.assign(state.snackBar, {
        status: "",
        message: "",
        show: false,
      });
    },
  },
};
