<template>
  <v-menu offset-y nudge-bottom="8" v-model="menuState" :close-on-content-click="false" transition="slide-y-transition">
    <!-- Control -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :title="$t('mainHeader.applications')"
        class="activator-btn mr-4 mr-md-2"
        icon
        large
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <!-- Content -->
    <v-container tag="article" :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
      <header>
        <h4>{{ $t("appsMenuWindow.apps") }}</h4>
        <v-divider class="my-3" />
      </header>
      <div class="nav-body">
        <v-list tag="ul" nav>
          <v-list-item tag="li" v-for="(app, i) in getApps()" :key="i" class="d-flex justify-center">
            <!-- If (app === app) then externalLink = false -->
            <a
              v-if="app.externalLink === true"
              @click="closeMenu"
              class="link-item"
              :href="app.url"
              :title="app.brand"
              :target="app.slug"
            >
              <i class="logo-img" :class="app.slug"></i>
              <span class="brand-string wx-typo-sm">{{ app.brand }}</span>
            </a>
            <a v-if="app.externalLink === false" @click="closeMenu" class="link-item disabled">
              <i class="logo-img" :class="app.slug"></i>
              <span class="brand-string wx-typo-sm">{{ app.brand }}</span>
            </a>
          </v-list-item>
        </v-list>
      </div>
    </v-container>
  </v-menu>
</template>

<script>
export default {
  name: "AppsMenuWindow",
  data() {
    return {
      menuState: false,
      fullscreenState: false,
    };
  },
  computed: {},
  methods: {
    getApps() {
      let tilelyticsApp = {
        brand: "Tilelytics",
        slug: "tilelytics",
        url: process.env.VUE_APP_URL_TO_TILELYTICS,
        /* app === app */ externalLink: false,
      };
      let tileplusApp = {
        brand: "Tile+",
        slug: "tileplus",
        url: process.env.VUE_APP_URL_TO_TILEPLUS,
        externalLink: true,
      };
      return [tilelyticsApp, tileplusApp];
    },
    closeMenu() {
      this.menuState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.activator-btn {
  background-color: var(--color-control-background-theme);
}

// Vuetify menu
.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}
.container {
  padding-top: 20px;
  width: 270px;
  background-color: var(--color-flat-panel-theme);
}

// Vuetify list
.v-sheet.v-list {
  display: flex;
  width: 100%;
  padding: 20px 0;
  background-color: transparent;
  box-shadow: none;
}
.v-list--nav {
  .v-list-item {
    display: inline-flex;
    width: auto;
    padding: 0;
    margin-inline: 5%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    // <a>
    .link-item {
      display: block;
      margin-inline: auto;
      padding: 5px;
      color: var(--color-text-theme);
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        right: 0;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-border-theme);
        pointer-events: none;
        transition: var(--smooth-transition);
        transition-property: opacity, background-color;
        opacity: 0;
      }

      .logo-img,
      .brand-string {
        display: block;
        position: relative;
        z-index: 1;
        text-align: center;
      }

      .logo-img {
        width: 34px;
        height: 34px;
        margin-inline: auto;
        margin-bottom: 10px;
        background: White no-repeat center center;
        border-radius: var(--border-radius-sm);

        &.tilelytics {
          background-image: url("~@/assets/wx-logos/tilelytics_light.svg");
          background-size: 80%;
        }
        &.tileplus {
          background-image: url("~@/assets/wx-logos/tileplus_light.svg");
          background-size: 80%;
        }
        &.worxspace {
          background-image: url("~@/assets/wx-logos/worxspace_light.svg");
          background-size: 90%;
        }
      }

      // Events
      &:not(.disabled) {
        &:hover,
        &:focus {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
