import Vue from "vue";
import Vuetify from "vuetify";
import CustomIcon from "@/components/ui/CustomIcon";
import StyleValues from "@/styles/_values.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        neutral: StyleValues.neutralDark,
        primary: StyleValues.primaryDark,
        secondary: StyleValues.secondaryDark,
        info: StyleValues.infoDark,
        error: StyleValues.errorDark,
        success: StyleValues.successDark,
        warning: StyleValues.warningDark,
      },
      light: {
        neutral: StyleValues.neutralLight,
        primary: StyleValues.primaryLight,
        secondary: StyleValues.secondaryLight,
        info: StyleValues.infoLight,
        error: StyleValues.errorLight,
        success: StyleValues.successLight,
        warning: StyleValues.warningLight,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: StyleValues.smBreakpoint,
      sm: StyleValues.mdBreakpoint,
      md: StyleValues.lgBreakpoint,
      lg: StyleValues.xlBreakpoint,
    },
    scrollBarWidth: 0,
    mobileBreakpoint: "sm",
  },
  icons: {
    values: {
      // ONLY TileLytics
      quantityIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "quantity.svg",
        },
      },
      // Shared accross all wx apps
      alertIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "alert.svg",
        },
      },
      availabilityDowntimesIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "availability-downtimes.svg",
        },
      },
      calendarItemIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "calendar-item.svg",
        },
      },
      insightsIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "insights.svg",
        },
      },
      plannedReasonIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "downtime-planned.svg",
        },
      },
      unplannedReasonIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "downtime-unplanned.svg",
        },
      },
      factoryIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "factory.svg",
        },
      },
      graphIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "graph.svg",
        },
      },
      tableViewIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "table-view.svg",
        },
      },
      barChartViewIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "bar-chart-view.svg",
        },
      },
      lineChartViewIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "line-chart-view.svg",
        },
      },
      shiftChangeIcon: {
        component: CustomIcon,
        props: {
          svgFileName: "shift-change.svg",
        },
      },
    },
  },
});
