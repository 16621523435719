class RouteService {
  toRoot() {
    return {
      name: "Root",
    };
  }
  toHome() {
    return {
      name: "Home",
    };
  }
  toCompanyOverview() {
    return {
      name: "CompanyOverview",
    };
  }
  toDashboard(factoryId) {
    return {
      name: "Dashboard",
      params: {
        factoryId: factoryId,
      },
    };
  }
  toAvailabilityAndDowntime(factoryId) {
    return {
      name: "AvailabilityAndDowntime",
      params: {
        factoryId: factoryId,
      },
    };
  }
  toProductionDataExportRoute(factoryId) {
    return {
      name: "ProductionDataExport",
      params: {
        factoryId: factoryId,
      },
    };
  }
  toMyReportsRoute(factoryId) {
    return {
      name: "MyReports",
      params: {
        factoryId: factoryId,
      },
    };
  }
  toReportCreationFromTemplate(factoryId, template) {
    return {
      name: "ReportCreation",
      params: {
        factoryId: factoryId,
        template: template,
      },
    };
  }
  toReportEdition(factoryId, reportId) {
    return {
      name: "ReportEdition",
      params: {
        factoryId: factoryId,
        id: reportId,
      },
    };
  }
  toCustomPeriods() {
    return {
      name: "CustomPeriods",
    };
  }
  toCustomPeriodCreation() {
    return {
      name: "CustomPeriodCreation",
    };
  }
  toCustomPeriodEdition(periodId) {
    return {
      name: "CustomPeriodEdition",
      params: {
        id: periodId,
      },
    };
  }
  toFactories() {
    return {
      name: "Factories",
    };
  }
  toFactory(factoryId) {
    return {
      name: "FactoryWizard",
      params: {
        id: factoryId,
      },
    };
  }
  toTermsOfService() {
    return {
      name: "TermsOfService",
    };
  }
  toUserNotEnabled() {
    return {
      name: "UserNotAuthorized",
    };
  }
  toShiftChanges() {
    return {
      name: "ShiftChanges",
    };
  }
  toProductionUnitShiftChanges(factoryId, productionUnitId) {
    return {
      name: "ShiftChanges",
      params: {
        factoryId: factoryId,
        productionUnitId: productionUnitId,
      },
    };
  }
  toLineStarts() {
    return {
      name: "LineStarts",
    };
  }
  toProductionUnitLineStarts(factoryId, productionUnitId) {
    return {
      name: "LineStarts",
      params: {
        factoryId: factoryId,
        productionUnitId: productionUnitId,
      },
    };
  }
  toProductionUnitProductChanges(factoryId, productionUnitId) {
    return {
      name: "ProductChanges",
      params: {
        factoryId: factoryId,
        productionUnitId: productionUnitId,
      },
    };
  }
  toPlannedDowntimeWithTarget(factoryId, productionUnitId, downtimeReasonId) {
    return {
      name: "PlannedDowntimesWithTarget",
      params: {
        factoryId: factoryId,
        productionUnitId: productionUnitId,
        downtimeReasonId: downtimeReasonId,
      },
    };
  }
}

export default new RouteService();
