import AccountService from "../../components/account/AccountService";
import i18n from "../../i18n";

export default {
  namespaced: true,

  state: {
    account: null,
    accountId: null,
    isAccountSettingsFetched: false,

    selectedProdLinesNames: [],
    selectionShift: [],
    selectedProducts: [],
    selectionDowntimeTypes: ["Unplanned", "Planned"],
    downtimeTypeOptions: [
      { text: i18n.t("DowntimeTypes.Planned"), value: "Planned" },
      { text: i18n.t("DowntimeTypes.Unplanned"), value: "Unplanned" },
    ],
  },
  getters: {
    account(state) {
      return state.account;
    },
    accountId(state) {
      return state.accountId;
    },
    selectedProdLinesNames(state) {
      return state.selectedProdLinesNames;
    },
    selectionShift(state) {
      return state.selectionShift;
    },
    selectedProducts(state) {
      return state.selectedProducts;
    },
    selectionDowntimeTypes(state) {
      return state.selectionDowntimeTypes;
    },
    downtimeTypeOptions(state) {
      return state.downtimeTypeOptions;
    },
  },
  actions: {
    fetchAccountSettings({ commit }, accountId) {
      return AccountService.getAccount(accountId)
        .then((accountResponse) => {
          commit("setAccountId", accountId);
          commit("setAccount", accountResponse.data);
          commit("updateIsAccountSettingsFetched", true);
        })
        .catch((error) => {
          commit("updateIsAccountSettingsFetched", false);
          console.log(error);
        });
    },

    updateSelectedProdLines({ commit }, data) {
      commit("updateSelectedProdLines", data);
    },
    updateSelectedShifts({ commit }, data) {
      commit("updateSelectedShifts", data);
    },
    updateSelectedProducts({ commit }, data) {
      commit("updateSelectedProducts", data);
    },
    updateSelectionDowntimeTypes({ commit }, data) {
      commit("updateSelectionDowntimeTypes", data);
    },
  },
  mutations: {
    setAccount(state, data) {
      state.account = data;
    },
    setAccountId(state, id) {
      state.accountId = id;
    },
    updateIsAccountSettingsFetched(state, data) {
      state.isAccountSettingsFetched = data;
    },
    updateSelectedProdLines(state, data) {
      state.selectedProdLinesNames = data;
    },
    updateSelectedShifts(state, data) {
      state.selectionShift = data;
    },
    updateSelectedProducts(state, data) {
      state.selectedProducts = data;
    },
    updateSelectionDowntimeTypes(state, data) {
      state.selectionDowntimeTypes = data;
    },
  },
};
